import React from 'react'
import CallingCard from '../CallingCard'
//import Logo from '../Logo'
import './style.less'

const clsPrefix = 'our-infomation'
interface OurInfomationProps {
    callingCardList: Array<{
        title: string,
        children: React.ReactNode
    }>,
    children: React.ReactNode
    className?: string
}
const OurInfomation: React.FC<OurInfomationProps> = ({
     callingCardList = [],
     children = '',
    className = ''
 }) => {
  return (
    <div className={`${clsPrefix} ${className}`}>
          <div className={`${clsPrefix}-container`}>
              {callingCardList.map((item, index) => (
                  <CallingCard title={item.title} key={index}>
                      {item.children}
                  </CallingCard>
              ))}
              {children}
            {/*<CallingCard*/}
            {/*  title='联系我们'*/}
            {/*  children={*/}
            {/*    <div style={{ paddingLeft: '13px', marginTop: '14px' }}>*/}
            {/*      <div>18222670914</div>*/}
            {/*      <div>邮箱：suyuchun@icloudfuture.com</div>*/}
            {/*      <div>地址：北京市海淀区世纪科贸大厦B座</div>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*/>*/}
            {/*<CallingCard*/}
            {/*  title='合作微信'*/}
            {/*  children={*/}
            {/*    <div style={{ display: 'flex', paddingLeft: '8px', marginTop: '5px' }}>*/}
            {/*      <div style={{ marginRight: '20px' }}>*/}
            {/*        <div>流量主合作</div>*/}
            {/*        <div className='qrcode-container'>*/}
            {/*          <img src='//pic.kuaizhan.com/g3/e4/49/6266-dc93-4113-81ad-8966a65575ae83' />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div>*/}
            {/*        <div>广告主合作</div>*/}
            {/*        <div className='qrcode-container'>*/}
            {/*          <img src='//pic.kuaizhan.com/g3/92/ea/d418-1cdb-4f00-8daa-f695824786b022' />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*/>*/}
            {/*<div className={`${clsPrefix}__logo-part`}>*/}
            {/*  <Logo />*/}
            {/*  <div>专业的流量变现服务平台</div>*/}
            {/*</div>*/}
          </div>
        </div>
  )
}
export default OurInfomation
