import * as React from 'react';
import { Layout } from '@/layout/kmLayout';
import { BrowserRouter as Router } from 'react-router-dom';
import { KmShortUrl } from './short-url';

/** 快码短链落地页 */
const App: React.FC = () => {
  return (
    <Router >
      <Layout >
        <KmShortUrl />
      </Layout>
    </Router>
  )
}

export default App;