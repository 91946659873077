import { URL_ACTIVE_CODE, URL_SHORT_API, URL_HELP_CENTER } from '@/constants/kmHomepage';

export const kmLinkData: {
  link: string;
  title: string;
  needLogin?: boolean;
  outerLink?: true;
  isOpenNewWindow?: boolean;
  isNew?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}[] = [
  {
    link: '/',
    title: '首页',
    outerLink: true,
    isOpenNewWindow: false,
  },
  {
    link: URL_HELP_CENTER,
    title: '帮助中心',
    outerLink: true,
    isOpenNewWindow: false,
  },
  {
    link: '/contact',
    title: '联系我们',
    outerLink: true,
    isOpenNewWindow: true,
  },
  {
    link: URL_SHORT_API,
    title: '短链API',
    outerLink: true,
    isOpenNewWindow: true,
  },
  {
    link: '/pricing',
    title: '定价方案',
  },
];
