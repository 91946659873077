function currying(fn, ...args1): any {
  return function (...args2): any {
      return fn(...args1, ...args2);
  };
}

export { currying };


/**
 * bem 命名生成函数
 * @param b block
 * @param e element
 * @param m modify
 */
function bem(b: string, e?: string, m?: string): string {
    let result = b;
    if (e) result = result + '__' + e;
    if (m) result = result + '--' + m;
    return result;
}

const genBlock = (blockName: string): ((arg?: string, arg2?: string) => string) =>
    currying(bem, blockName);

export { bem, genBlock };
