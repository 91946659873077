import * as React from "react";
import { useState } from "react";
import AdvancedLink from "../AdvancedLink";
import "./index.less";

const clsPrefix = "layout-header";

const links: {
  link: string;
  title: string | React.ReactNode;
  needLogin?: boolean;
  outerLink?: true;
  isOpenNewWindow?: boolean;
  isNew?: boolean;
}[] = [
  {
    link: "/",
    title: "首页",
  },
  {
    link: "/v2/success-case",
    title: "成功案例",
  },
  {
    link: "/v2/about-us",
    title: "关于我们",
  },
  {
    link: "/v1/manage/dashboard",
    title: "后台管理",
    needLogin: true,
    outerLink: true,
  },
];

const LinkItem: React.FC<{
  link: string;
  title: string;
  outerLink?: boolean;
  isOpenNewWindow?: boolean;
  isNew?: boolean;
  onClick?: () => void;
  currentLink?: string;
}> = ({
  link,
  title,
  outerLink,
  isNew,
  isOpenNewWindow,
  onClick = () => null,
  currentLink
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      key={link}
      className={`link ${isNew ? "link-new" : ""}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <AdvancedLink
        href={link}
        linkType={outerLink ? "outer" : "inner"}
        isOpenNewWindow={isOpenNewWindow || false}
        className={currentLink === link ? "current-layout" : ""}
        onClick={onClick || null}
      >
        {title}
      </AdvancedLink>
      <div
        className={
          currentLink === link || isHovered ? "selected" : "unselected"
        }
      />
    </div>
  );
};

const LinksNav: React.FC<{
  navLinks?: Array<{
    link: string;
    title: string;
    outerLink?: boolean;
    isOpenNewWindow?: boolean;
    isNew?: boolean;
    onClick?: () => void;
  }>;
  currentLink?: string;
  className?: string;
}> = ({ navLinks = links, currentLink = "", className = "" }) => {
    console.log(currentLink, 'currentLink', window.location.pathname)
  return (
    <div className={`${clsPrefix}__links-nav ${className}`}>
      {// @ts-ignore
      navLinks && navLinks.length? navLinks.map((item, index) => (
        <LinkItem {...item} currentLink={currentLink} key={index} />
      )) : ""}
    </div>
  );
};

export default LinksNav;
