import { Dialog } from '@kzui/core';
import * as React from 'react';
import CustomerServiceLeft from '@/assets/images/km/indexpage-customer-service/customer-service-left.png';
import CustomerServiceRight from '@/assets/images/km/indexpage-customer-service/customer-service-right.jpg';
import OnLineCustomerImage from '@/assets/images/km/online-customer.png';
import CustomerServiceImage from '@/assets/images/km/customer-service.png';
import { genBlock } from '@/utils/bem';
import './style.less';

const blockName = 'c-online-customer';
const block = genBlock(blockName);
const OnLineCustomer:React.FC<{
    visible?: boolean;
    onFloatImgClick?: () => void
    onDialogClose?: () => void
    floatVisible?: boolean;
}> = ({
    visible,
    onFloatImgClick,
    onDialogClose,
    floatVisible = true
}) => {
    const [customerIncrement, setCustomerIncrement] = React.useState(0)

    React.useEffect(() => {
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    const handleScroll = function() {
        const clientHeight = document.documentElement.clientHeight,
            scrollTop = document.documentElement.scrollTop,
            scrollHeight = document.documentElement.scrollHeight;
    
        if (scrollHeight - scrollTop - clientHeight <= 200) {
            setCustomerIncrement(200 - (scrollHeight - scrollTop - clientHeight))
        } else {
            setCustomerIncrement(0);
        }
    };
    

    return (
        <div className={block()}>
            <Dialog
                hide={visible}
                title="联系我们"
                className={block('dialog')}
                actions={[]}
                onClose={() => onDialogClose?.()}
            >
            <div className={block('dialog-content')}>
                <div className={block('dialog-content-left')}>
                <p>商务合作：</p>
                <div>
                    <p>suyuchun@icloudfuture.com</p>
                    <p>免费客服热线</p>
                    <p>400-106-0025</p>
                    <p>周一至周五 9:30-18:30</p>
                    <p>地址：北京市海淀区世纪科贸大厦B座</p>
                </div>
                </div>
                <div
                className={`${block('dialog-content-line')} ${block(
                    'dialog-content-line',
                    'left'
                )}`}
                ></div>
                <div className={block('dialog-content-center')}>
                <p>客服人员：</p>
                <img src={CustomerServiceLeft} alt="" />
                </div>
                <div
                className={`${block('dialog-content-line')} ${block(
                    'dialog-content-line',
                    'right'
                )}`}
                ></div>
                <div className={block('dialog-content-right')}>
                <p>客服人员：</p>
                <img src={CustomerServiceRight} alt="" />
                </div>
            </div>
            </Dialog>

            {floatVisible ? <img
                src={OnLineCustomerImage}
                alt=""
                style={{ bottom: `calc(15% + ${customerIncrement}px)`}}
                className={block('img')}
                onClick={onFloatImgClick}
            /> : null}

            {floatVisible ? <img src={CustomerServiceImage} alt="" className={block("customer-service")} style={{ bottom: `calc(15% + ${customerIncrement + 60}px)`}} onClick={onFloatImgClick} />: null}
        </div>
    )
}

export default OnLineCustomer;