export const RECORD_NUM_ENUM = {
    'cyads.cn': {number: '京ICP备18063655号-2'},
    'www.cyads.cn': {number: '京ICP备18063655号-2'},
    'kzurl.cn': {number: '京ICP备18063655号-3'},
    'www.kzurl.cn': {number: '京ICP备18063655号-3'},
    'kzurl01.cn': {number: '京ICP备18063655号-4'},
    'kzurl02.cn': {number: '京ICP备18063655号-5'},
    'kzurl03.cn': {number: '京ICP备18063655号-6'},
    'kzurl04.cn': {number: '京ICP备18063655号-7'},
    'kzurl05.cn': {number: '京ICP备18063655号-8'},
    'kzurl06.cn': {number: '京ICP备18063655号-9'},
    // 京ICP备18063655号-10 本来就没有
    'kzurl07.cn': {number: '京ICP备18063655号-11'},
    'kzurl08.cn': {number: '京ICP备18063655号-12'},
    'kzurl09.cn': {number: '京ICP备18063655号-13'},
    'kzcdn.cn': {number: '京ICP备18063655号-14'},
    'kzurl10.cn': {number: '京ICP备18063655号-15'},
    'kzurl11.cn': {number: '京ICP备18063655号-16'},
    'kzurl12.cn': {number: '京ICP备18063655号-17'},
    'kzurl13.cn': {number: '京ICP备18063655号-18'},
    'kzurl14.cn': {number: '京ICP备18063655号-19'},
    'kzurl15.cn': {number: '京ICP备18063655号-20'},
    'kzurl16.cn': {number: '京ICP备18063655号-21'},
    'kzurl17.cn': {number: '京ICP备18063655号-22'},
    'kzurl18.cn': {number: '京ICP备18063655号-23'},
    'wenjuanmiao.com': {number: '京ICP备18063655号-24'},
    'kzurl19.cn': {number: '京ICP备18063655号-25'},
    'kzurl20.cn': {number: '京ICP备18063655号-26'},
    '3kma.cn': {number: '京ICP备18063655号-27'},
    '1kma.cn': {number: '京ICP备18063655号-28'},
    '2kma.cn': {number: '京ICP备18063655号-29'},
    'kkkma.com': {number: '京ICP备18063655号-30'},
    '4kma.cn': {number: '京ICP备18063655号-31'},
    '5kma.cn': {number: '京ICP备18063655号-32'},
    '6kma.cn': {number: '京ICP备18063655号-33'},
    '7kma.cn': {number: '京ICP备18063655号-34'},
    '8kma.cn': {number: '京ICP备18063655号-35'},
    '9kma.cn': {number: '京ICP备18063655号-36'},
    'kma1.cn': {number: '京ICP备18063655号-37'},
    'kma2.cn': {number: '京ICP备18063655号-38'},
    'kma3.cn': {number: '京ICP备18063655号-39'},
    'kma4.cn': {number: '京ICP备18063655号-40'},
    'kma5.cn': {number: '京ICP备18063655号-41'},
    '1kml.cn': {number: '京ICP备18063655号-42'},
    '3kml.cn': {number: '京ICP备18063655号-43'},
    '4kml.cn': {number: '京ICP备18063655号-44'},
    '5kml.cn': {number: '京ICP备18063655号-45'},
    '2kml.cn': {number: '京ICP备18063655号-46'},
    'kurl11.cn': {number: '京ICP备18063655号-47'},
    'kurl12.cn': {number: '京ICP备18063655号-48'},
    'kurl13.cn': {number: '京ICP备18063655号-49'},
    'kurl15.cn': {number: '京ICP备18063655号-50'},
    'kurl14.cn': {number: '京ICP备18063655号-51'},
    'kurl18.cn': {number: '京ICP备18063655号-52'},
    'kurl19.cn': {number: '京ICP备18063655号-53'},
    'kurl17.cn': {number: '京ICP备18063655号-54'},
    'kurl20.cn': {number: '京ICP备18063655号-55'},
    'kurl16.cn': {number: '京ICP备18063655号-56'},
    // 小码
    'kurl02.cn': {number: '京ICP备2022000770号-1', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    'kurl01.cn': {number: '京ICP备2022000770号-2', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    'kurl04.cn': {number: '京ICP备2022000770号-3', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    'kurl03.cn': {number: '京ICP备2022000770号-4', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    'kurl05.cn': {number: '京ICP备2022000770号-5', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    'kurl06.cn': {number: '京ICP备2022000770号-6', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    'kurl07.cn': {number: '京ICP备2022000770号-7', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    'kurl08.cn': {number: '京ICP备2022000770号-8', text: "Copyright@2022 小码快跑 版权所有 北京小码快跑科技有限公司 "},
    // 个人号
    'www.icloudfuture.com': {number: '京ICP备18058716号-1', text: " "},
}
