import React from 'react'
import './index.less'

const clsPrefix = 'kz-ui-drawer'
const Drawer: React.FC<{
  title?: string | React.ReactNode
  visible: boolean,
  onClose: () => void,
  style?: React.CSSProperties,
  className?: string
}> = ({
  title = '',
  children,
  visible = false,
  onClose = () => null,
  style = {},
  className = '',
}) => {
  let visibleClass = visible ? `${clsPrefix}__show` : ''

  function handleClose() {
    onClose()
  }

  return (
    <div className={`${clsPrefix} ${className} ${visibleClass}`} style={style}>
      <div className={`${clsPrefix}__mask`} onClick={handleClose} />
      <div className={`${clsPrefix}__content-wrapper`} style={!visible ? {  } : {}}>
        <div className={`${clsPrefix}__content`}>
          <div className={`${clsPrefix}__header`}>
            {title}
          </div>
          <div className={`${clsPrefix}__body`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Drawer
