/**
 * 设置cookie
 * https://blog.csdn.net/l1028386804/article/details/51691035
 */

export function setCookie (cname: string, cvalue: string, exdays: number) {
  let d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires
}

// 清除cookie
export function clearCookie (name: string) {
  setCookie(name, '', -1)
}

/**
 * 获取cookie值
 * @param name 
 * @returns 
 */
export function getCookie (name: string) {
  const cookieArr = document.cookie.split(';')
  const valueArr = cookieArr.filter(item => item.indexOf(name) !== -1);
  return valueArr[0] && valueArr[0].split('=')[1]
}

/**
 * 解析url参数
 * @param {String} name 参数名
 * @return {String} 值
 */
export const getValueFromLocationSearch = (name: string) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return window.decodeURI(r[2])
  }

  return null
}


/**
 * 处理移动端和 PC 端需要跳转不同链接
 * @param { object } 值
 */
export function diffEndJump ({
  href,
  wapHref,
  isOpenNewWindow = true,
}: {
  href?: string,
  wapHref?: string | null,
  isOpenNewWindow?: boolean,
  disableInWx?: boolean,
}) {

  if (window && window.screen.width < 1024) {
    if (!href && !wapHref) {
      return
    }

    if (wapHref === null) {
      return
    }
    // @ts-ignore
    window.location.href = wapHref || href
  } else {
    if (!href) {
      return
    }
    const newTab = isOpenNewWindow ? window.open('') : window
    // @ts-ignore
    newTab.location.href = href
  }
}

/**
 * 获取当前年份
 */
export function getCurrentYear () {
  const now = new Date()
  const currentYear = now.getFullYear()
  return currentYear
}

/**
 * 生成uuid
 * @param len 
 * @param radix 
 * @returns 
 */
export function uuid(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid: string[] = [];
  const _radix = radix || chars.length;
  if (len) {
      // Compact form
      for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * _radix)];
  } else {
      // rfc4122, version 4 form
      let r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (let i = 0; i < 36; i++) {
          if (!uuid[i]) {
              r = 0 | (Math.random() * 16);
              uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
          }
      }
  }
  return uuid.join('');
}


export const injectGuestId = () => {
  let guestId = localStorage.getItem('guest_uid');
  if (!guestId) {
      guestId = `9999${uuid(6, 10)}`;
      localStorage.setItem('guest_uid', guestId);
  }

  return guestId;
};

export function isWap() {
    if (window) {
        return window.screen.width <= 1024 || window.innerWidth <= 1024;
    }
    return false;
}