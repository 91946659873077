import request from "./request";
import { injectGuestId } from '@/utils/tools'; 

const kmHost = 'https://kuaima.kuaizhan.com'

export const createShortUrl = (opt: { realUrls: string[]; domain: string }) =>
  request(`${kmHost}/api/v1/shortLink/createShortLink`, {
    method: "POST",
    json: {
      accessMode: "FAST",
      accessPass: "",
      domain: "kzurl.cn",
      domainType: "OFFICIAL",
      expireMonth: 3,
      realUrls: ["kuaizhan.com"],
      remark: "落地页生成的短链",
      ...opt,
    },
    headers: {
      'X-Guest-Id': injectGuestId()
    }
  });

export const getUserInfo = () =>
  request(`${kmHost}/kzplatform/api/user/userInfo`, {
    method: "GET",
    mode: 'cors',
    headers: {
      'X-Guest-Id': injectGuestId()
    }
  });

export const getOfficalDomain = () =>
  request(`${kmHost}/api/v1/shortLink/domain/getShortLinkOfficialDomains`, {
    method: "GET",
    mode: 'cors',
    headers: {
      'X-Guest-Id': injectGuestId()
    }
  });
