import React from 'react'
import { Link } from 'react-router-dom'
import { AdvancedLinkProp } from '../../types/components'
import Icon from '../Icon';

const AdvancedLink: React.FC<AdvancedLinkProp> = ({
  href = '',
  linkType,
  isOpenNewWindow,
  children,
  className,
  style,
  icon,
  title,
  onClick
}) => (
  <>
    {
      icon ? <Icon name={icon} /> : null
    }
    {
      linkType !== 'outer' ?
        <Link
          to={href}
          className={className || ''}
          style={style || {}}
          onClick={(e) => {
            onClick?.(e)
          }}
        >
          {title || children}
        </Link> :
        <a
          className={className || ''}
          style={style || {}}
          href={href}
          target={isOpenNewWindow ? '_blank' : ''}
          onClick={(e) => {
            onClick?.(e)
          }}
        >
          {title || children}
        </a>
    }
  </>
)

export default AdvancedLink
