import * as React from 'react';
import { useLocation } from 'react-router';
import { kmLinkData } from './linkData';
import { Button, Drawer, AdvancedLink } from '@/components';
import LayoutHeader from '@/components/LayoutHeader';
import LinksNav from '@/components/LayoutHeader/LinksNav';
import { getUserInfo } from '@/request/km';
import {
  URL_REGISTER_WAP,
  URL_REGISTER_PC,
  URL_LOGIN_WAP,
  URL_LOGIN_PC,
  URL_LOGOUT_PC,
  URL_LOGOUT_WAP,
  URL_ACTIVE_CODE,
  URL_CREATE_AC,
  URL_CREATE_SU,
  URL_SHORT_URL,
} from '@/constants/kmHomepage';
import { KmLogo } from './km-logo';
import { genBlock } from '@/utils/bem';
import { diffEndJump } from '@/utils/tools';

import '@/assets/common.less';
import './index.less';
import OnLineCustomer from '@/components/OnlineCustomer';
interface LayoutProps {
  layoutData?: any;
  children?: any;
}

const block = genBlock('km-layout');

export const Layout: React.FC<LayoutProps> = ({ children, layoutData }) => {
  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);
  const [isLogin, setIsLogin] = React.useState(false);
  const [account, setAccount] = React.useState('');
  const [isConfirmVisible, setIsConfirmVisible] = React.useState(true);

  React.useEffect(() => {
    fetchUserInfo();
  }, []);

  function fetchUserInfo() {
    getUserInfo()
      .then((res) => {
        if (res && res.data && res.data.user_id) {
          setIsLogin(true);
          window.sessionStorage.setItem('user_id', res?.data?.user_id);
        } else {
          window.sessionStorage.removeItem('user_id');
          setIsLogin(false);
        }

        if (res && res.data && res.data.username) {
          setAccount(res && res.data && res.data.username);
          window.sessionStorage.setItem(
            'verification_status',
            res?.data?.verification_status
          );
        } else {
          window.sessionStorage.removeItem('verification_status');
        }
      })
      .catch((err) => {
        console.log(err, 'err');
        setIsLogin(false);
      });
  }

  function handleLogin() {
    diffEndJump({
      href: URL_LOGIN_PC,
      wapHref: URL_LOGIN_WAP,
      isOpenNewWindow: false,
    });
  }

  function handleRegister() {
    diffEndJump({
      href: URL_REGISTER_PC,
      wapHref: URL_REGISTER_WAP,
      isOpenNewWindow: false,
    });
  }

  function handleLogout() {
    diffEndJump({
      href: URL_LOGOUT_PC,
      wapHref: URL_LOGOUT_WAP,
      isOpenNewWindow: false,
    });
  }

  const drawerHeader = (
    <div className={block('drawer-header')}>
      <KmLogo />
    </div>
  );

  const getKmLinkData = () => {
    const curLinkData = [...kmLinkData];
    Object.assign(curLinkData[2], {
      onClick: (e) => {
        e.preventDefault();
        setIsConfirmVisible(false);
      },
    });

    switch (useLocation().pathname) {
      case '/' : {
        Object.assign(curLinkData[0], {
          link: isLogin? '/v1/workspace' : URL_LOGIN_PC,
          title: '管理后台'
        })
        break;
      }
      case '/short-url': {
        curLinkData.push({
          link: URL_ACTIVE_CODE,
          title: '活码介绍',
          outerLink: true,
          isOpenNewWindow: true,
        });
        break;
      }
      case '/active-code': {
        curLinkData.push({
          link: URL_SHORT_URL,
          title: '短链介绍',
          outerLink: true,
          isOpenNewWindow: true,
        });
        break;
      }
      default:
        break;
    }

    return curLinkData;
  };

  return children ? (
    <div className={block()}>
      <LayoutHeader className={block('header')}>
        <div className={block('left')}>
          <KmLogo />
          <LinksNav navLinks={getKmLinkData()} className={block('nav')} currentLink={useLocation().pathname} />
        </div>
        <div className={block('right')}>
          {isLogin ? (
            <div className={'user-info'}>
              <span className="info-unit">你好{`, ${account}`}</span>
              <a className="info-unit" href="/v1/workspace">
                进入后台
              </a>
              <span className="info-unit" onClick={handleLogout}>
                退出
              </span>
            </div>
          ) : (
            <div className="login-buttons">
              <AdvancedLink
                href={URL_CREATE_AC}
                linkType="outer"
                className="login-a"
              >
                免登陆试用群活码
              </AdvancedLink>
              <AdvancedLink
                href={URL_CREATE_SU}
                linkType="outer"
                className="login-a"
              >
                免登陆试用短链生成
              </AdvancedLink>
              <Button
                className="login-btn"
                themeColor="#198ED9"
                onClick={handleLogin}
              >
                登录
              </Button>
              <Button
                className="register-btn"
                type="primary"
                themeColor="#198ED9"
                onClick={handleRegister}
              >
                立即注册
              </Button>
            </div>
          )}
          <div
            className="wap-menu"
            onClick={() => setIsDrawerVisible((visible) => !visible)}
          >
            <div className="short-line" />
            <div className="long-line" />
            <div className="short-line" />
          </div>
        </div>
      </LayoutHeader>
      {children}
      <Drawer
        visible={isDrawerVisible}
        title={drawerHeader}
        onClose={() => setIsDrawerVisible(false)}
        className={block('drawer')}
        style={{ top: '50px' }}
      >
        <a className="drawer-link" href="/">
          首页
        </a>
        <a className="drawer-link" href={`/help`}>
          帮助中心
        </a>
        <AdvancedLink
          href="/contact"
          className={'drawer-link'}
          title="联系我们"
          onClick={kmLinkData[2].onClick}
        />
        <a className="drawer-link" href={'/link-api'}>
          短链API
        </a>
        <a className="drawer-link" href={URL_ACTIVE_CODE}>
          活码介绍
        </a>
        {isLogin ? (
          <Button
            className="login-out-btn"
            themeColor="#188ED8"
            onClick={handleLogout}
          >
            退出登录
          </Button>
        ) : (
          ''
        )}
      </Drawer>
      <OnLineCustomer
        visible={isConfirmVisible}
        onDialogClose={() => setIsConfirmVisible(true)}
        onFloatImgClick={() => setIsConfirmVisible(false)}
        floatVisible={useLocation().pathname !== '/pricing'}
      />
    </div>
  ) : null;
};
