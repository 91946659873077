import React from 'react'
import '@kuaizhan/kmanage-font/iconfont.css'

interface IconProps {
  name: string,
  style?: React.CSSProperties
  className?: string;
  onClick?: () => void;
}
const Icon: React.FC<IconProps> = ({
  name = '',
  style = {},
  className = '',
  onClick = () => null,
}) => {
  return (
    <i className={`iconfont icon-${name} ${className}`} style={style} onClick={onClick}></i>
  )
}

export default Icon
