import React from 'react'
import './style.less'

interface CallingCardProps {
  title: string,
  children: React.ReactNode
}
const clsPrefix = 'calling-card'
const CallingCard: React.FC<CallingCardProps> = ({
  title = '联系我们',
  children = ''
}) => {
  return (
    <div className={clsPrefix}>
      <div className={`${clsPrefix}__title`}>
        <div className='vertical-line' />
        <span>{title}</span>
      </div>
      <div className={`${clsPrefix}__cut`} />
      <div className={`${clsPrefix}__content`}>
        {children}
      </div>
    </div>
  )
}

export default CallingCard
