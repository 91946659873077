import { getCookie } from '@/utils/tools';

// service-menu
import ChangyanIconImage from '../assets/images/kz-hompage-icon/changyan.png'
import KuaimaIconImage from '../assets/images/kz-hompage-icon/kuaima.png'
import OfficialAccountIconImage from '../assets/images/kz-hompage-icon/offcial-acc.png'
import SiteIconImage from '../assets/images/kz-hompage-icon/site.png'
import VoteIconImage from '../assets/images/kz-hompage-icon/vote.png'
import WeappIconImage from '../assets/images/kz-hompage-icon/weapp.png'

// site-panel
import SiteCreateImage from '../assets/images/create-site.png'
import SiteServiceImage from '../assets/images/upgrade-service.png'
import SiteCommunityImage from '../assets/images/article-community.png'
import SiteTplImage from '../assets/images/molto-template.png';

// official-account-panel
import OffcAttractFansImage from '../assets/images/offc-attract-fans.png'
import OffcEnhanceWxImage from '../assets/images/offc-enhance-wx.png'
import OffcRefineFanseImage from '../assets/images/offc-refine-fans.png'
import OffcDataVisualizationImage from '../assets/images/offc-data-visualization.png'

// marketing-panel
import MarketingChangyanImage from '../assets/images/marketing-changyan.png'
import MarketingChangyingImage from '../assets/images/marketing-changying.png'
import MarketingVoteImage from '../assets/images/marketing-vote.png'

// kuaikeduo
import KkdMultiSceneImage from '../assets/images/kkd-multi-scene.png'
import KkdEShopImage from '../assets/images/kkd-e-shop.png'
import KkdVipManagementImage from '../assets/images/kkd-vip-management.png'
import KkdMultiMarketingImage from '../assets/images/kkd-multi-marketing.png'

import { AdvancedLinkProp } from '../types/components'
import {getCurrentYear} from "../utils/tools";
import { RECORD_NUM_ENUM } from '@/constants/record-number';

export const URL_LOGIN_WAP = `//www.kuaizhan.com/v2/passport/login?utm_source=m`
export const URL_LOGIN_PC = '/passport/login?refer=/v3/workspace'

export const URL_REGISTER_WAP = '//www.kuaizhan.com/v2/passport/reg'
export const URL_REGISTER_PC = '//www.kuaizhan.com/passport/'

export const URL_LOGOUT_WAP = '//www.kuaizhan.com/v2/passport/logout'
export const URL_LOGOUT_PC = '//www.kuaizhan.com/passport/logout'

// 业务线 - PC
export const URL_SITE = '/v3/site'
export const URL_WX_INTRODUCE = 'https://www.kuaizhan.com/wx'
export const URL_WX_LIST = '/v3/weixin/list'
export const URL_WX_LIST_WAP = '//www.kuaizhan.com/v3/weixin/list'
export const URL_KUAIKEDUO = '//kuaikeduo.kuaizhan.com'
export const URL_CHANGYAN = '//changyan.kuaizhan.com'
export const URL_CHANGYING = 'http://www.cyads.cn'
export const URL_VOTE = '/vote/backend.html#/list/pages/0'
export const URL_WEAPP = '/v3/homepage/weapp'


// 业务线 - WAP
// export const WAP_HOST = navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1? 
//   'z.kuaizhan.com' : 'z.kuaizhan.com'
export const URL_SITE_WAP = '//www.kuaizhan.com/v2/sites/'
// export const URL_WX_WAP = (wapHost) => '//' + wapHost + '/23/31/p71663677847f41'
export const URL_WEAPP_WAP = (wapHost) =>'//' + wapHost + '/25/4/p5927392833c601'
export const URL_SITE_DETAIL = '//www.kuaizhan.com/v3/homepage' // 点击建站、详情介绍
export const URL_SITE_DETAIL_WAP = (wapHost) =>`//${wapHost}/98/20/p592790571b9544`
export const URL_POST_SPREAD_WAP = `//cyads.kuaizhan.com/pages/post-spread/index`

// footer内容
// export const FOOTER_CONTENT = `Copyright@${getCurrentYear()} Kuaizhan.com Inc. All Rights Reserved. 云站科技 版权所有 北京云站科技有限公司 <a href='//beian.miit.gov.cn/' target='_blank'>京ICP备18063655号-1</a>`
export const FOOTER_CONTENT = {
  contentBefore: RECORD_NUM_ENUM[location?.host]?.text || `Copyright@${getCurrentYear()} Kuaizhan.com Inc. All Rights Reserved. 云站科技 版权所有 北京云站科技有限公司 `,
  linkUrl: `//beian.miit.gov.cn/`,
  linkContent: RECORD_NUM_ENUM[location?.host]?.number || '京ICP备18063655号-1',
  contentAfter: ''
}

export const serviceMenuLinks = (wapHost, isLogin, isNewKmUser = true) => [
  {
    href: URL_SITE_DETAIL,
    wapHref: URL_SITE_WAP,
    text: '建站',
    imgUrl: SiteIconImage,
    tip: '零门槛快速建站'
  },
  {
    href: isLogin? URL_WX_LIST : URL_WX_INTRODUCE,
    wapHref: URL_WX_INTRODUCE,
    text: '公众号',
    imgUrl: OfficialAccountIconImage,
    tip: '快速吸粉、高效管理'
  },
  // {
  //   href: URL_KUAIKEDUO,
  //   // 证书失效百度审核无法通过，切为登陆页
  //   wapHref:  navigator.userAgent.toLowerCase().indexOf('baidu') > -1 ? URL_LOGIN_WAP : URL_POST_SPREAD_WAP,
  //   text: isWap ? '数据增长' : '快客多',
  //   imgUrl: KuaikeduoIconImage,
  //   tip: '电商、服务营销解决方案'
  // },
  {
    href: isNewKmUser ? 'https://kuaima.kuaizhan.com' : 'https://kuaima.kuaizhan.com/v1/workspace',
    wrapHref: 'https://kuaima.kuaizhan.com',
    text: '快码短链',
    imgUrl: KuaimaIconImage,
    tip: '专业的活码和短链生成工具',
  },
  {
    href: '/v3/homepage/vote',
    // 字节小程序审核，暂时跳登录页
    wapHref: URL_LOGIN_WAP,
    text: '快投票',
    tip: '个性化投票系统',
    imgUrl: VoteIconImage
  },
  {
    href: URL_CHANGYAN,
    // 证书失效百度审核无法通过，切为登陆页
    wapHref:  navigator.userAgent.toLowerCase().indexOf('baidu') > -1? URL_LOGIN_WAP : undefined,
    text: '畅言云评',
    imgUrl: ChangyanIconImage,
    tip: '智能评论管理'
  },
  {
    href: URL_WEAPP,
    // 证书失效百度审核无法通过，切为登陆页
    wapHref:  navigator.userAgent.toLowerCase().indexOf('baidu') > -1? URL_LOGIN_WAP : URL_WEAPP_WAP(wapHost),
    text: '小程序',
    imgUrl: WeappIconImage,
    tip: '拖拽式快速搭建小程序'
  }
]

export const SITE_DESCRIPTIONS = [
  {
    title: '三分钟快速建站',
    descriptions: [
      '模板丰富',
      '拖拽编辑',
      '一键生成APP',
      '快速预览'
    ]
  },
  {
    title: '独立域名、SEO优化更多增值服务',
    descriptions: [
      '百度快速收录',
      'META设置等功能助力SEO优化',
      '快速获取流量',
      '支持绑定独立域名'
    ]
  },
  {
    title: '文章社区电商',
    descriptions: [
      '成熟稳定的内容管理系统',
      '提升内容管理效率'
    ]
  },
  {
    title: '海量模板和营销建站',
    descriptions: [
      '助力企业打造专属官网',
      '提升品牌价值',
      '满足电商交易场景',
      '多商铺、拼团等多种营销功能'
    ]
  }
]

export const SITE_IMAGES = [
  SiteCreateImage,
  SiteServiceImage,
  SiteCommunityImage,
  SiteTplImage
]

export const OFFICIAL_DESCRIPTIONS = [
  {
    title: [
      '多元途径快速吸粉',
      '指数级获取粉丝'
    ],
    descriptions: [
      '自动粉丝标签',
      '参数二维码',
      '投票吸粉',
      '微积粉'
    ],
    imgUrl: OffcAttractFansImage,
    iconStyle: {
      color: '#3765FF'
    }
  },
  {
    title: [
      '增强微信推送能力',
      '提升粉丝活跃度'
    ],
    descriptions: [
      '无限群发',
      '互动后推送',
      '定时推送',
      '个性化推送'
    ],
    imgUrl: OffcEnhanceWxImage,
    iconStyle: {
      color: '#FC4D5A'
    }
  },
  {
    title: [
      '精细化粉丝管理',
      '提升管理效率'
    ],
    descriptions: [
      '粉丝标签',
      '自定义菜单',
      '自定义回复',
      '消息管理'
    ],
    imgUrl: OffcRefineFanseImage,
    iconStyle: {
      color: '#9A43FB'
    }
  },
  {
    title: [
      '可视化数据统计',
      '运营数据一键导出'
    ],
    descriptions: [
      '粉丝新增',
      '活跃',
      '留存数据'
    ],
    imgUrl: OffcDataVisualizationImage,
    iconStyle: {
      color: '#59AB8F'
    }
  }
]

export const KKD_DESCRIPTIONS = [
  {
    title: ['多场景、多行业'],
    descriptions: [
      '支持电商、预约服务门店等多种经营方式',
      '支持小程序和H5、适应微信生态'
    ],
    imgUrl: KkdMultiSceneImage
  },
  {
    title: ['专业的会员管理系统'],
    descriptions: [
      '会员管理、会员成长、会员积分、会员储值等专业的会员管理功能',
      '活跃客户、留住客户、持续消费'
    ],
    imgUrl: KkdVipManagementImage
  },
  {
    title: ['电商分销'],
    descriptions: [
      '快速吸粉、提升复购',
      '分销员管理、支持查看分销员业绩数据和分销员关系网络',
      '刷单预警、保障安全'
    ],
    imgUrl: KkdEShopImage
  },
  {
    title: ['多种营销玩法', '刺激客户消费'],
    descriptions: [
      `多拼团、积分商城、优惠券、大转盘、赠品、发券宝不断上线新玩法，助力商家线上销售`
    ],
    imgUrl: KkdMultiMarketingImage
  }
]

export const MARKETING_DESCRIPTIONS = [
  {
    title: '快投票',
    descriptions: [
      '扫码关注公众号投票，通过投票增长粉丝',
      '精准数据监控实时统计',
      '礼物投票功能、为参选者送礼物、投票活动有收入',
      '智能防刷票'
    ],
    href: URL_VOTE,
    // 字节小程序审核，暂时跳转登录
    wapHref: URL_LOGIN_WAP,
    imgUrl: MarketingVoteImage
  },
  {
    title: '畅言云评',
    descriptions: [
      '智能评论管理',
      'PC/WAP/APP多端适配',
      '权限管理/管理员分权限支持多人审核',
      '移动端、反垃圾、多插件'
    ],
    imgUrl: MarketingChangyanImage,
    themeColor: '#7700F1',
    // 字节小程序审核，暂时跳转登录
    wapHref: navigator.userAgent.toLowerCase().indexOf('toutiao') > -1 ? URL_LOGIN_WAP : URL_CHANGYAN,
    href: URL_CHANGYAN
  },
  {
    title: '畅盈广告',
    descriptions: [
      '丰富广告形式',
      '高额收益、随时提现',
      '数据统计',
      '一键接入、精准服务'
    ],
    imgUrl: MarketingChangyingImage,
    themeColor: '#3D53EC',
    href: URL_CHANGYING
  }
]

export const KZ_MORE_SERVICE_LINKS = (wapHost): AdvancedLinkProp[] => [
  {
    title: '案例',
    href: '/v3/homepage/cases',
    linkType: 'outer'
  },
  {
    title: '关于我们',
    href: '/v3/homepage/about',
    linkType: 'outer'
  },
  {
    title: '快站大学',
    href: `//${wapHost}/90/3/p2649405090dde7`,
    linkType: 'outer'
  },
  {
    title: '帮助中心',
    href: '//wiki.kuaizhan.com/pub/%E9%A6%96%E9%A1%B5',
    linkType: 'outer'
  }
]

export const btnStats = {
  userPanel: {
    category: 'kz_site_website_v1_free_create',
    bizType: 2
  },
  officialAccount: {
    category: 'kz_wx_account_v1_free_create',
    bizType: 1
  },
  kuaikeduo: {
    category: 'kz_kkd_website_v1_free_create',
    bizType: 3
  },
  vote: {
    category: 'kz_vote_website_v1_free_create',
    bizType: 4
  },
  changyan: {
    category: 'kz_changyan_website_v1_free_create',
    bizType: 5
  },
  changying: {
    category: 'kz_changying_website_v1_free_create',
    bizType: 7
  }
}

export const bannerStats = [
  {
    category: 'kz_homepage_banner_default_image',
    bizType: 2
  },
  {
    category: 'kz_homepage_banner_wx_account_image',
    bizType: 1
  }
]

export const siteDetailStat = {
  category: 'kz_site_website_detail',
  bizType: 2
}

export const serviceMenuStats = [
  {
    category: 'kz_site_website_free_create',
    bizType: 2
  },
  {
    category: 'kz_wx_account_website_free_create',
    bizType: 1
  },
  {
    category: 'kz_kkd_website_free_create',
    bizType: 3
  },
  {
    category: 'kz_vote_website_free_create',
    bizType: 4
  },
  {
    category: 'kz_changyan_website_free_create',
    bizType: 5
  },
  {
    category: 'kz_weapp_website_free_create',
    bizType: 6
  }
]
