import React from 'react'
import './index.less'

const clsPrefix = 'layout-footer'
interface FooterProps {
    contentBefore: string,
    linkUrl: string,
    linkContent: string,
    contentAfter: string
}
const Footer: React.FC<FooterProps> = ({
    contentBefore= '',
    linkUrl='',
    linkContent='',
    contentAfter=''
}) => {

  return (
    <div className={clsPrefix}>
        {contentBefore}
        <a href={linkUrl} target='_blank'>{linkContent}</a>
        {contentAfter}
    </div>
  )
}

export default Footer
