import * as React from "react";
import { genBlock } from "@/utils/bem";
import "./index.less";

const imageArr = Array(6)
  .fill(0)
  .map((_item, index) => {
    return require(`@/assets/images/km/introduce-${index}.png`);
  });

const introduceData = [
  {
    img: imageArr[0],
    title: "极速",
    desc: (
      <span>
        “快码短链”专属大量服务器
        <br />
        支持高并发
        <br />
        真正的极速无感跳转
      </span>
    ),
  },
  {
    img: imageArr[1],
    title: "安全",
    desc: (
      <span>
        多重链接防护技术
        <br />
        支持独享域名
        <br />
        尽全力保证短链存活时间
      </span>
    ),
  },
  {
    img: imageArr[2],
    title: "无劫持",
    desc: (
      <span>
        郑重声明“快码目前仅为引流”
        <br />
        后续推出“快码活链”等功能或许收费
        <br />
        但“快码短链”绝无劫持，完全免费
      </span>
    ),
  },
  {
    img: imageArr[3],
    title: "全国通行",
    desc: (
      <span>
        专业运维团队支持，全国连通性强
        <br />
        避免短链在部分地区打不开的问题
      </span>
    ),
  },
  {
    img: imageArr[4],
    title: "多维度数据统计",
    desc: (
      <span>
        PV/UV/IP，地区系统访问时间访问来源等等
        <br />
        全平台最详细的数据统计
      </span>
    ),
  },
  {
    img: imageArr[5],
    title: "随时修改原链接",
    desc: (
      <span>
        生成短链后随时修改目标链接
        <br />
        避免推广后无法更换链接的问题
      </span>
    ),
  },
];

const block = genBlock("km-introduce");

export const Introduce: React.FC = () => {
  return (
    <div className={block()}>
      {introduceData.map((item, index) => (
        <div className={block("item")}>
          <img src={item.img} alt={item.title} className={block("item-img")} />
          <div className={block("item-title")}>{item.title}</div>
          <div className={block("item-desc")}>{item.desc}</div>
        </div>
      ))}
    </div>
  );
};
