import React from 'react';
import '@kzui/core/dist/styles.css';
import ReactDOM from 'react-dom';
import App from './page/km/app-dl';

/** 快码短链落地页 */

import * as serviceWorker from './serviceWorker.js';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();