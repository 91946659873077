import qs from 'qs'
interface RequestOpts<T> {
  qs?: any,
  json?: T,
  body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined,
  method?: string,
  headers?: any,
  form?: boolean,
  formData?: object,
  timeout?: number,
  credentials?: 'omit' | 'same-origin' | 'include' | undefined,
  baseURL?: string,
  mode?: 'cors' | 'no-cors' | 'same-origin';
}

function fetchRequest (url: string, opts: RequestOpts<object> = {}) {
  let timeout = 10000
  let _fetchOpts: RequestInit = {
    credentials: 'include',
    method: 'GET',
    headers: {}
  }
  if (opts.qs) {
    // @ts-ignore
    url += (url.indexOf('?') === -1 ? '?' : '&') + qs.stringify(opts.qs, { indices: !!!opts.qsStringfyOptions })
  }
  if (opts.json) {
    // @ts-ignore
    _fetchOpts.headers['Content-Type'] = 'application/json'
    _fetchOpts.body = JSON.stringify(opts.json)
  }
  if (opts.form) {
    // @ts-ignore
    _fetchOpts.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // @ts-ignore
    _fetchOpts.body = qs.stringify(opts.form, { indices: !!!opts.qsStringfyOptions })
  }
  if (opts.method) {
    _fetchOpts.method = opts.method
  }
  if (opts.formData) {
    // _fetchOpts.headers['Content-Type'] = 'multipart/form-data';
    const form = new FormData()
    for (let key in opts.formData) {
      // @ts-ignore
      form.append(key, opts.formData[key])
    }
    _fetchOpts.body = form
  }
  if (opts.timeout !== undefined) {
    timeout = opts.timeout
  }
  if (opts.credentials) {
    _fetchOpts.credentials = opts.credentials
  }
  /* top level priority varibale set in the end */
  if (opts.headers) {
    Object.assign(_fetchOpts.headers, opts.headers)
  }
  if (opts.body) {
    _fetchOpts.body = opts.body
  }

  if (opts.mode) {
    _fetchOpts.mode = opts.mode;
}

  const baseURL = opts.baseURL || ''
  return timeoutFetch(fetch(baseURL + url, _fetchOpts), timeout)
              .then(checkStatus)
              .catch(handleError)
}

/**
 * 检查接口响应状态码
 *
 * @param {Object} response fetch返回的响应对象
 * @return {Object} 状态码正常时返回响应本身，否则返回 reject 信息
 */
 function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
      if (!response.body) {
          // TODO  可能会有bug
          return Promise.resolve({
              code: 200,
              data: {},
              msg: '',
          });
      }
      return response.json();
  } else {
      return Promise.reject(response);
  }
}

/**
* 异常处理函数，包含错误提示
*
* @param {Object} e 错误信息
*/
function handleError(e) {
  if (e !== 'timeout') {
      const responseStatus = e?.status;
      console.log(e, 'e');
      return e
          ?.json?.()
          .then(response => {
              return Promise.reject({
                  status: responseStatus,
                  code: response?.code,
                  msg: response?.msg || getErrorMsgByStatusCode(responseStatus),
              });
          })
          .catch(err => {
              return Promise.reject({
                  status: e.status,
                  msg: err?.msg || getErrorMsgByStatusCode(e.status),
              });
          });
  } else {
      console.error('请求错误提示', { status: '', msg: '网络加载失败，请检查网络设置' });
      return Promise.reject({ status: '', msg: '网络加载失败，请检查网络设置' });
  }
}

/**
 * 返回状态码对应文本提示信息
 *
 * @param {number} code 响应状态码
 * @return {string} 文本提示
 */
function getErrorMsgByStatusCode (code: number) {
  let result = '未知错误'
  if (code >= 400 && code < 500) {
    switch (code) {
      case 401:
        result = '您尚未登录,请登录后访问.'
        break
      case 403:
        result = '您所请求的资源被禁止访问.'
        break
      case 404:
        result = '您所请求的资源并不存在.'
        break
      case 405:
        result = '非法请求被禁止.'
        break
      default:
        result = `抱歉，程序出了问题(${code}).`
    }
  } else if (code >= 500 && code < 600) {
    result = '服务器出错啦.'
  }
  return result
}

function timeoutFetch (fetchPromise: any, timeout: any) {
  let abortFn: any = null
  const abortPromise = new Promise(function (resolve, reject) {
    abortFn = function () {
      reject('timeout')
    }
  })
  const abortablePromise = Promise.race([
    fetchPromise,
    abortPromise
  ])

  setTimeout(function () {
    abortFn()
  }, timeout)

  return abortablePromise
}

export default fetchRequest
