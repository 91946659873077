import React from "react";
import { OurInfomation, AdvancedLink } from "@/components";
import { AdvancedLinkProp } from '@/types/components';
import { URL_SHORT_API, URL_DOMAIN_LIB } from '@/constants/kmHomepage';
import KzConsultImage from '@/assets/images/kz-consult-qrcode.png';
import KzWxQrcodeImage from '@/assets/images/kz-wx-qrcode.png';
import './style.less';
import { genBlock } from "@/utils/bem";

const clsPrefix = 'c-our-infomation';
const block = genBlock('km-our-info');

export const KmOurInfomation: React.FC = () => {

  const MORE_SERVICE_LINKS: AdvancedLinkProp[] = [
    {
      title: '域名库',
      href: URL_DOMAIN_LIB,
      linkType: 'outer',
    },
    {
      title: '短链API',
      href: URL_SHORT_API,
      linkType: 'outer',
    },
  ];

  const CALLINGKMINDEXPAGE = [
    {
      title: '更多服务',
      children: (
        <div className={`${clsPrefix}__col`}>
          {MORE_SERVICE_LINKS.map((item) => (
            <AdvancedLink
              {...item}
              isOpenNewWindow
              style={{ display: 'block' }}
            />
          ))}
        </div>
      ),
    },
    {
      title: '在线咨询',
      children: (
        <div className={`${clsPrefix}__col`}>
          <div className="info-item">免费客服热线</div>
          <a href="tel:400-106-0025" className="info-item">
            400-106-0025
          </a>
          <div className="info-item">周一至周五 9:30-18:30</div>
          <div className="info-item">地址：北京市海淀区世纪科贸大厦B座</div>
        </div>
      ),
    },
    {
      title: '联系我们',
      children: (
        <div className={`${clsPrefix}__col`}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '20px' }}>
              <div style={{ marginBottom: '9px' }}>客服人员</div>
              <div className="qrcode-container">
                <img src={KzConsultImage} alt="" />
              </div>
            </div>
          </div>
          <div className="info-item" style={{ marginTop: '12px' }}>
            商务合作：
            <a className="info-item" href="malito:suyuchun@icloudfuture.com">
              suyuchun@icloudfuture.com
            </a>
          </div>
        </div>
      ),
    },
    {
      title: '关注我们',
      children: (
        <div className={`${clsPrefix}__col`}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '20px' }}>
              <div style={{ marginBottom: '9px' }}>公众号：快站</div>
              <div className="qrcode-container">
                <img src={KzWxQrcodeImage} alt="" />
              </div>
              <div style={{ marginTop: '9px' }}>微博：快站服务</div>
              <div>知乎：快站</div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <OurInfomation
      callingCardList={CALLINGKMINDEXPAGE}
      children={[]}
      className={`${clsPrefix}__information ${block('infomation')}`}
    />
  );
};
