import * as React from "react";
import { Input, Button, RadioGroup, notification } from "@kzui/core";
import { Introduce } from "./introduce";
import copyToClipboard from "copy-to-clipboard";
import { createShortUrl, getOfficalDomain } from "@/request/km";
import kmLogoImg from "@/assets/images/km/km-logo.png";
import { AdvancedLink, Footer } from "@/components";
import { FOOTER_CONTENT } from "@/constants/kmHomepage";
import { KmOurInfomation } from "../components/our-infomation";
import { genBlock } from "@/utils/bem";
import "./index.less";

const VERIFY_TEXT = ['未认证', '认证中', '认证成功', '认证失败'];
const block = genBlock("km-squeeze-container");

export const KmShortUrl: React.FC = () => {
  const [inputValue, setInputValue] = React.useState("");
  const [radioValue, setRadioValue] = React.useState("kzurl.cn");
  const [shortUrl, setShortUrl] = React.useState("");
  const [domainOpts, setDomainOpts] = React.useState([]);
  const [anClass, setAnClass] = React.useState("");

  React.useEffect(() => {
    fetchDomainOpts();
  }, []);

  React.useEffect(() => {
    let curAnClass = "";
    if (!anClass && shortUrl) {
      curAnClass = "hasurl";
    }
    setAnClass(curAnClass);
  }, [shortUrl]);

  const fetchDomainOpts = () => {
    getOfficalDomain().then((res) => {
      if (res?.code === 200) {
        const curOpts =
          res?.data.map((item) => ({ text: item, value: item })) || [];
        setDomainOpts(curOpts.slice(0, 3));
        setRadioValue(curOpts[0]?.value || "");
      }
    })
  };

  const handleTransitionEnd = () => {
    if (shortUrl) {
      setAnClass("hasurl");
    }
  };

  const handleInput = (val: string) => {
    setInputValue(val);
  };

  const handleRadio = (val: string) => {
    setRadioValue(val);
  };

  const handleCopy = (val: string) => {
    const copyRes = copyToClipboard(val);
    if (copyRes) {
      notification.success("短链复制成功");
    } else {
      notification.error("复制失败");
    }
  };

  const handleCreateClick = () => {
    createShortUrl({
      realUrls: [inputValue],
      domain: radioValue,
    })
      .then((res) => {
        if (res?.code === 200) {
          const url = res?.data?.[0]?.shortUrl || "";
          handleCopy(url);
          setShortUrl(url);
        } else {
          notification.error(res?.msg || "网络错误", 1);
        }
      })
      .catch((err) => {
        notification.error(err?.msg || "网络错误", 1);
      });
  };

  const verifyStatus = parseInt(
    window.sessionStorage.getItem("verification_status") || "0"
  );

  const user_id = window.sessionStorage.getItem('user_id');
  return (
    <div className={block()}>
      <div className={block("container")}>
        <div className={block("title-wrap")}>
          <img src={kmLogoImg} alt="" className={block("title-wrap-logo")} />
          <div className={block("title-wrap-title")}>快码短链</div>
        </div>
        <div className={block("function-wrap")}>
          <div className={block("input-label")}>输入需要缩短的长网址</div>
          <div className={block("input-wrap")}>
            <Input
              className={block("input-wrap-input")}
              value={inputValue}
              onChange={(val) => handleInput(val.value)}
            />
            <Button
              className={block("input-wrap-button")}
              type="confirm"
              last
              onClick={handleCreateClick}
            >
              生成短链
            </Button>
          </div>
          <RadioGroup
            options={domainOpts}
            layout="horizontal"
            value={radioValue}
            className={block("radio")}
            onChange={(val) => handleRadio(val.value)}
          />
          {!user_id && (
            <span style={{ color: "red", paddingTop: "10px" }}>
              您当前还未登录，生成的短链将有访问数量限制，登录后生成短链无限制访问
            </span>
          )}
          {user_id && verifyStatus !== 2 && (
            <span style={{ color: "red", paddingTop: "10px" }}>
              您当前账号{VERIFY_TEXT[verifyStatus]}，生成的短链每条短链UV上限20
              <AdvancedLink
                href="https://www.kuaizhan.com/v3/verify/entry/"
                linkType="outer"
                style={{color: '#115BE8'}}
              >
                &nbsp;&nbsp;去认证
              </AdvancedLink>
            </span>
          )}
          {

          }
          <div
            className={block(`short-url-wrap ${anClass}`)}
            onClick={() => handleCopy(shortUrl)}
            onTransitionEnd={handleTransitionEnd}
          >
            {shortUrl}
          </div>
        </div>
        <Introduce />
      </div>
      <div className={block("footer")}>
        <div className={block("tip-wrap")}>
          <p className={block("tip")}>
            应公安部要求，我们将对生成短链进行检测，如发现违法违规的内容，包括但不限于色情，博彩，欺诈等相关网址链接，将会对链接进行封禁处理
          </p>
          <a className={block("support")} href="/">
            快码提供技术支持
          </a>
        </div>
      </div>
      <KmOurInfomation />
      <Footer
        contentBefore={FOOTER_CONTENT.contentBefore}
        linkUrl={FOOTER_CONTENT.linkUrl}
        linkContent={FOOTER_CONTENT.linkContent}
        contentAfter={FOOTER_CONTENT.contentAfter}
      />
    </div>
  );
};
