import React from 'react'
import KmLogoImage from '@/assets/images/km/km-logo.png'
import './index.less'

export const KmLogo: React.FC<{}> = () => {

  const goHomePage = () => {
    window.location.href = '/';
  }

  return (
    <div className='km-logo' onClick={goHomePage}>
      {
       <img src={KmLogoImage} alt='LOGO' className='km-logo__image' />
      }
      <span className="km-logo__title" >快码</span>
    </div>
  )
}
