import React from 'react'
import LinksNav from './LinksNav'
import './index.less'

const clsPrefix = 'layout-header'

const LayoutHeader: React.FC<{
  className?: string
}> = ({
  children,
  className = ''
}) => {
  return (
    <div className={`${clsPrefix} ${className}`}>
      {children ? children : (
        <>
          <LinksNav />
        </>
      )}
    </div>
  )
}

export default LayoutHeader
