// 快码短链首页icon
import CustomerLiveCode from "@/assets/images/km/indexpage-icon/customer-live-code.png";
import GenerateShortLink from "@/assets/images/km/indexpage-icon/generate-short-link.png";
import QroupLiveCodeClone from "@/assets/images/km/indexpage-icon/group-live-code-clone.png";
import ShortLinkApiClone from "@/assets/images/km/indexpage-icon/short-link-api-clone.png";
import ShortLinkRevert from "@/assets/images/km/indexpage-icon/short-link-revert.png";


import BannerActiveCode from "@/assets/images/km/banner/active-code.png";
import BannerGroupActiveCode from '@/assets/images/km/banner/group-active-code.png';
import BannerCustActiveCode from "@/assets/images/km/banner/custom-active-code.png";
import BannerShortUrl from "@/assets/images/km/banner/short-url.png";

export const URL_LOGIN_PC =
  "//www.kuaizhan.com/v3/passport/login?refer=https://kuaima.kuaizhan.com/v1/workspace";
export const URL_LOGIN_WAP =
  "//www.kuaizhan.com/v2/passport/login?callback=https://kuaima.kuaizhan.com/v1/workspace";

export const URL_LOGOUT_WAP = "//www.kuaizhan.com/v2/passport/logout?callback=https://kuaima.kuaizhan.com";
export const URL_LOGOUT_PC = "//www.kuaizhan.com/passport/logout";

export const URL_REGISTER_WAP = "//www.kuaizhan.com/v2/passport/reg?callback=https://kuaima.kuaizhan.com";
export const URL_REGISTER_PC = "//www.kuaizhan.com/passport/";

/** 群活码生成页 */
export const URL_CREATE_AC =
  "//kuaima.kuaizhan.com/v1/dynamic-qrcode/group/instruction";
/** 短链生成页 */
export const URL_CREATE_SU =
  "//kuaima.kuaizhan.com/v1/short-urls/config/generate";
/** 客服活码生成页 */
export const URL_CREATE_CAC =
  "//kuaima.kuaizhan.com/v1/dynamic-qrcode/customer-services/instruction";
/** 轮播图链接 */
export const URL_BANNERS = [URL_CREATE_AC, URL_CREATE_CAC, URL_CREATE_SU];

/** 活码介绍页 */
export const URL_ACTIVE_CODE = "//kuaima.kuaizhan.com/active-code";
/** 短链介绍页 */
export const URL_SHORT_URL = "//kuaima.kuaizhan.com/short-url";

/** 帮助中心 */
export const URL_HELP_CENTER =
  "https://www.yuque.com/kuaima-odaac/tvg44b/dpuuuc";
/** 短链API */
export const URL_SHORT_API = "https://www.yuque.com/kuaima-odaac/tvg44b/sd9ucw";
/** 域名库 */
export const URL_DOMAIN_LIB = "https://kuaima.kuaizhan.com/v1/domains-repo";
/** 快码轮播图配置列表 */
export const BANNER_LIST_HOME = [
    // {
    //     img: 'https://pic.kuaizhan.com/g3/b0/8f/adce-41c4-451d-b3d4-51487762212221',
    //     url: 'https://www.kuaizhan.com/v3/individuals/anni',
    // },
    {
        img: BannerGroupActiveCode,
        url: URL_CREATE_AC,
    },
    {
        img: BannerCustActiveCode,
        url: URL_CREATE_CAC,
    },
    {
        img: BannerShortUrl,
        url: URL_CREATE_SU,
    },
];
/** 活码落地页轮播图配置列表 */
export const BANNER_LIST_AC = [
  {
    img: BannerActiveCode,
    url: URL_CREATE_AC,
  }
]

// 短链首页
export const shortLinkData = [
  {
    title: "活码",
    icons: [
      {
        text: "群活码",
        imgUrl: QroupLiveCodeClone,
      },
      {
        text: "客服活码",
        imgUrl: CustomerLiveCode,
      },
    ],
    selected: [
      "二维码分组",
      "多种二维码展示模式",
      "二维码失效提醒",
      "详细的数据统计",
      "防止重复扫码",
      "客服功能",
      "自定义活码页面",
    ],
    buttonLinkUrl: URL_ACTIVE_CODE,
  },
  {
    title: "短链",
    icons: [
      {
        text: "生成短链",
        imgUrl: GenerateShortLink,
      },
      {
        text: "短链还原",
        imgUrl: ShortLinkRevert,
      },
      {
        text: "短链API",
        imgUrl: ShortLinkApiClone,
      },
    ],
    selected: [
      "极速",
      "多维度数据统计",
      "绝无流量劫持",
      "独享域名",
      "支持API",
      "短链还原",
      "全国通行",
    ],
    buttonLinkUrl: URL_SHORT_URL,
  },
];

export { FOOTER_CONTENT } from "./kzHomepage";
