import React, { CSSProperties } from 'react'
import './index.less'

const clsPrefix = 'c-button'
const Button: React.FC<{
  className?: string
  type?: 'primary' | 'default'
  themeColor?: string
  onClick?: () => void
  children: React.ReactNode
  style?: CSSProperties;
}> = ({
  className = '',
  type = 'default',
  themeColor = '#207CD3',
  onClick = () => null,
  children,
  style = {}
}) => {
  const typeClass = `${clsPrefix}--${type}`
  const themeStyle = {
    color: type === 'default' ? themeColor : '',
    borderColor: themeColor,
    backgroundColor: type === 'primary' ? themeColor : ''
  }

  function handleClick () {
    if (onClick) {
      onClick()
    }
  }
  return (
    <button
      className={`${clsPrefix} ${typeClass} ${className}`}
      onClick={handleClick}
      style={{ ...themeStyle, ...style }}
    >
      {children}
    </button>
  )
}

export default Button
